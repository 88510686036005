(function($){
    
    /**
     * @author Richard Cox [richard@emosaic.co.uk]
     * @summary Shortcode - Row for columns
     */
    Drupal.behaviors.cha_row = {
        
        attach: function (context, settings) {
            
            /**
             * @author Richard Cox [richard@emosaic.co.uk]
             * @summary Equal heights for *** columns
             * @description Will apply the same height to each first child inside each column inner within the given row
             */
            $.each($('.js-row-columns-match > .row-inner', context), function(){
                
                var row = $(this);
                
                var byRow = row.data().matchAll == true ? true : false;
                
                var column = $('> .sc-type-col > .sc-type-col-inner', row).matchHeight({
                    byRow: byRow,
                    //property: 'min-height'
                    property: 'height' // Use height for vertical alignments as table-cell doesn't recognise min-height
                });
            });
        }
    };
})(jQuery);
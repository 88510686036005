(function($){

    //Google Map
    function initialize() {
        var $ = jQuery,
        mapCanvas = $('.map-canvas');

        mapCanvas.each(function () {
            var $this           = $(this),
            zoom            = 8,
            lat             = -34,
            lng             = 150,
            scrollwheel     = true,
            draggable       = true,
            mapType         = google.maps.MapTypeId.ROADMAP,
            title           = '',
            contentString   = '',
            dataZoom        = $this.attr('data-zoom'),
            dataLat         = $this.attr('data-lat'),
            dataLng         = $this.attr('data-lng'),
            dataType        = $this.attr('data-type'),
            dataScrollwheel = $this.attr('data-scrollwheel'),
            dataHue         = $this.attr('data-hue'),
            dataTitle       = $this.attr('data-title'),
            dataImage       = $this.attr('data-image'),
            dataContent     = $this.html();
    //$this.html('');

    if (dataZoom !== undefined && dataZoom !== false) {
        zoom = parseFloat(dataZoom);
    }

    if (dataLat !== undefined && dataLat !== false) {
        lat = parseFloat(dataLat);
    }

    if (dataLng !== undefined && dataLng !== false) {
        lng = parseFloat(dataLng);
    }

    if (dataScrollwheel !== undefined && dataScrollwheel !== false) {
        scrollwheel = dataScrollwheel;
    }

    if (dataType !== undefined && dataType !== false) {
        if (dataType == 'satellite') {
            mapType = google.maps.MapTypeId.SATELLITE;
        } else if (dataType == 'hybrid') {
            mapType = google.maps.MapTypeId.HYBRID;
        } else if (dataType == 'terrain') {
            mapType = google.maps.MapTypeId.TERRAIN;
        }
    }

    if (dataTitle !== undefined && dataTitle !== false) {
        title = dataTitle;
    }

    // if( navigator.userAgent.match(/iPad|iPhone|Android/i) ) {
    //     draggable = false;
    // }

    var mapOptions = {
        zoom        : zoom,
        scrollwheel : scrollwheel,
        draggable   : draggable,
        center      : new google.maps.LatLng(lat, lng),
        mapTypeId   : mapType
    };

    var map = new google.maps.Map($this[0], mapOptions);
    var image = Drupal.settings.basePath + '/sites/all/themes/site_theme/images/map-marker-default.png';

    if (dataContent !== undefined && dataContent !== false) {
        contentString = '<div class="map-content">' +
        '<h3 class="title">' + title + '</h3>' +
        dataContent +
        '</div>';
    }

    var infowindow = new google.maps.InfoWindow({
        content: contentString
    });

    var image = {
        url: dataImage,
        scaledSize: new google.maps.Size(45, 45)
    };

    var marker = new google.maps.Marker({
        position : new google.maps.LatLng(lat, lng),
        map      : map,
        icon     : image,
    title    : title
    });

    if (dataContent !== undefined && dataContent !== false) {
        google.maps.event.addListener(marker, 'click', function() {
            infowindow.open(map,marker);
        });
    }

    if (dataHue !== undefined && dataHue !== false) {
        var styles = [
        {
            stylers : [
            { hue : dataHue }
            ]
        }
        ];

        map.setOptions({styles: styles});
    }
    });
    }

    window.onload = initialize;

    // Remove mobile map overlay on click
    $('.map-canvas').each(function(){
        $(this).on('click', function(){
            $(this).addClass('active-map')
        });
    });

})(jQuery);
(function($){
    /**
     * @author Richard Cox [richard@emosaic.co.ul]
     * @summary Shortcode - Slider
     */
    Drupal.behaviors.cha_slider = {
        
        attach: function (context, settings) {
            
            $.each($('.js-slider', context), function(){
                
                var slider = $(this);
                
                var sliderAPI = $('> .slider-inner', slider);
                
                // Listeners have to be set before slick is called otherwise they will not bind on correctly

                // On init
                sliderAPI.on('init.slick', function(event, slick){
                    slickSetLoadInPlace(slick);
                });

                // After position / size changes
                slider.on('setPosition.slick', function(event, slick){
                    
                    throttle = slick.slickGetOption('groupings') == true ? false : true;
                    
                    slickSetVariableWidths(slick, throttle);
                });
                
                // Initialise the slider
                sliderAPI.slick({
                    appendArrows: $(this),
                    appendDots: $(this),
                    nextArrow: slickNextNavigation(sliderAPI),
                    prevArrow: slickPreviousNavigation(sliderAPI)
                });
                
                /**
                 * 
                 * @param {type} slider
                 * @returns {undefined}
                 */
                function slickSetLoadInPlace(api)    {
                    
                    if(api.slickGetOption('loadInPlace') == true)    {
                        
                        $('a[href*=#]', api.$slider).bind('click',function(e){
                            e.preventDefault();

                            var slide = $($(this).attr('href'));
                            
                            // Go to slide by index - 0 based
                            api.slickGoTo($(slide, api).index());

                            // Scroll to the top of the slide if set for this breakpoint
                            if(api.slickGetOption('loadInPlaceMoveTop') == true)   {
                                $("body, html").animate({
                                    scrollTop: slide.position().top
                                });
                            }
                        });
                    }
                }

                /**
                 * @author Richard Cox [richard@emosaic.co.uk]
                 * @description Because slides are inside one large container rather than 
                 * the viewport width we have to workout the width based on this container
                 * @param {Object} slider - the slider instance
                 * @param {Bool} force - force the slider
                 */
                function slickSetVariableWidths(api, force)   {
                    
                    if(api.slickGetOption('variableWidth') == true || force == true) {

                        slickWidth = parseInt(api.$slider.width());
                        
                        slickTrackWidth = parseInt(api.$slideTrack.width());
                        slides = $('> .slider-item', api.$slideTrack);
                        for (i = 0; i < slides.length; i++) {
                            slide = slides.eq(i);
                            
                            // Remove when slickSetVariableWidths is recalled
                            slide.css('width', '');

                            // We need the original percentage back first as we can't access the original css percentage rules
                            f = slide.width() / slickTrackWidth * 100;

                            // Get the relative percentage based on the expanded container
                            w = (slickWidth / slickTrackWidth) * f + '%';

                            // Then apply
                            slide.css('width', w);
                        }
                    }
                }

                /**
                 * @autor Richard Cox [richard@emosaic.co.uk]
                 * @param {Object} slider - the slider instance
                 * @returns {String} Returns a string to build the previous button
                 */
                function slickPreviousNavigation(api)  {
                    
                    return '<a class="slider-prev" href="javascript:;" data-role="none" aria-label="previous"></a>';
                }

                /**
                 * @autor Richard Cox [richard@emosaic.co.uk]
                 * @param {Object} slider - the slider instance
                 * @returns {String} Returns a string to build the previous button
                 */
                function slickNextNavigation(api) {
                    
                    return '<a class="slider-next" href="javascript:;" data-role="none" aria-label="next"></a>';
                }
            });
        }
    };
    
})(jQuery);
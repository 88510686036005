(function($){

    /**
     * Represents site
     * @function / @class
     * @param {type} name description
     * @this text
     * @fires text
     * @returns {type} description
     * @description text
     * @example text
     */
    Drupal.behaviors.cha_site = {
        attach: function (context, settings) {
            
            $.each($('.js-site-main-background-parallax', context), function(index, element)  {
                $(this).stellar({
                    horizontalScrolling: false
                });
            });
        }
    }
    
})(jQuery);
(function($){
    
    /**
     * @author Richard Cox [richard@emosaic.co.uk]
     * @summary Shortcode - Advanced container
     */

    Drupal.behaviors.cha_advancedContainer = {
        
        attach: function (context, settings) {
            
            /**
             * @author Richard Cox [richard@emosaic.co.uk]
             * @summary Advanced container minimum browser height
             * @description set the container with a minimum height of the current browser window
             */
            $(window).bind('resize', function(){
                $.each($('.js-advanced-container-browser-height', context), function(){
                    var height = $(window).height();
                    var container = $(this);
                    container.css('minHeight', height);
                });
            }).trigger('resize');

            /**
             * @author Richard Cox [richard@emosaic.co.uk]
             * @summary Advanced container for background Youtube videos
             * @description Implements a responsive, full width background video to a container
             */
            $.each($('.js-advanced-container-bg-video', context), function(){
                var video = $(this);
                var options = video.data().property;
                video.YTPlayer({
                    fitToBackground: true,
                    videoId: youtube_parser(options.videoURL),
                    mute: options.mute,
                    repeat: true
                });
            });
        }
    };
    
    function youtube_parser(url)    {
        var regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regex);

        if (match && match[2].length == 11) {
            return match[2];
        }
    }
    
})(jQuery);


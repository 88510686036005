(function($){

    /**
     * Represents column
     * @function / @class
     * @param {type} name description
     * @this text
     * @fires text
     * @returns {type} description
     * @description text
     * @example text
     */
    function column()   {
        
    }
    
})(jQuery);
(function ($) {

    /**
     * Represents articles-list
     * @function / @class
     * @param {type} name description
     * @this text
     * @fires text
     * @returns {type} description
     * @description text
     * @example text
     */
    Drupal.behaviors.cha_articles = {
        
        attach: function (context, settings) {

            $(".js-articles-block-row", context).each(function () {
                $(".js-articles-item", this).matchHeight({
                    property: 'min-height'
                });
            });

            $.each($(".js-articles-item-share", context), function(){
                $(this).hoverIntent(function () {
                    var listWidth = $(".js-articles-item-share ul").outerWidth();

                    $(".js-articles-item-share-button, ul", this).css({
                        left: -Math.abs(listWidth + 100) + "px"
                    });
                }, function () {

                    $(".js-articles-item-share-button, ul", this).css({
                        left: "-100px"
                    });
                });
            });
        }
    }

})(jQuery);
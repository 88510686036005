(function($){

    /**
     * Represents animate
     * @function / @class
     * @param {type} name description
     * @this text
     * @fires text
     * @returns {type} description
     * @description text
     * @example text
     */
    Drupal.behaviors.cha_animate = {
        
        attach: function (context, settings) {
            
            var transitionsSupported = ('transition' in document.documentElement.style) || ('WebkitTransition' in document.documentElement.style);
    
            $('[data-appear-animation]').each(function() {
                  var $this = $(this);
                  
                  $this.addClass('appear-animation');

                  if(transitionsSupported) {
                    $this.appear(function() {
                          var delay = ($this.attr('data-appear-animation-delay') ? $this.attr('data-appear-animation-delay') : 1);

                          if(delay > 1) $this.css('animation-delay', delay + 'ms');
                          $this.addClass($this.attr('data-appear-animation'));

                          setTimeout(function() {
                            $this.addClass('appear-animation-visible');
                          }, delay);
                    }, {accX: 0, accY: -150});
                    
                  } else {
                    $this.addClass('appear-animation-visible');
                  }
            });

            /* Animation Progress Bars */
            $('[data-appear-progress-animation]').each(function() {
                  var $this = $(this);

                  $this.appear(function() {
                    var delay = ($this.attr('data-appear-animation-delay') ? $this.attr('data-appear-animation-delay') : 1);

                    if(delay > 1) $this.css('animation-delay', delay + 'ms');

                    $this.find('.progress-bar').addClass($this.attr('data-appear-animation'));

                    setTimeout(function() {
                          $this.find('.progress-bar').animate({
                            width: $this.attr('data-appear-progress-animation')
                          }, 500, 'easeInCirc', function() {
                            $this.find('.progress-bar').animate({
                                  textIndent: 10
                            }, 1500, 'easeOutBounce');
                          });
                    }, delay);
                  }, {accX: 0, accY: -50});
            });
        }
    }
})(jQuery);
(function($){

    /**
     * Represents accordion-tabs
     * @function / @class
     * @param {type} name description
     * @this text
     * @fires text
     * @returns {type} description
     * @description text
     * @example text
     */
    Drupal.behaviors.cha_accordionTabs =    {
        
        attach: function (context, settings) {
            
            $.each($(".js-accordion-tabs", context), function(){
		
                var $this = $('.accordion-tabs-inner', this),
                    options = {};
                    
		// Get the options from the data attribute
                if (!jQuery.isEmptyObject($this.attr("data-options"))) {
                    options = JSON.parse($this.attr("data-options"));
		}
                
                // Set some classes
                options['classes'] =    {
                    stateDefault: '',
                    stateActive: 'is-active',
                    stateDisabled: 'is-disabled',
                    stateExcluded: 'is-excluded',
                    tab: '',
                    anchor: '',
                    panel: 'accordion-tabs-panel',
                    accordionTitle: 'accordion-tabs-title'
                };
                
                // Find the first 'open' tab if its set
                options['active'] = null;
                
                $.each($('> .' + options.classes.panel, $this), function(){
                    if($(this).data().open) {
                        options['active'] = $(this).index() - 1;
                    return false;
                    }
                });
                
                // The load listener needs to be set beforehand
                $this.on("tabs-load", function(event) {
                    $.each($('> .accordion-tabs-nav > li', $this), function(i, e){
                        tab = $(e);
                        if(tab.data().itemAccordianStyle)   {
                            $('.accordion-tabs-title', $this).eq(i).addClass($(this).data().itemAccordianStyle);
                            tab.removeAttr('data-item-accordian-style');
                        }
                    })
                });
                
		// Call responsive tabs
                $this.responsiveTabs(
                    options
                )
                
            });
        }
    };
})(jQuery);